import config from '@data/config.json';
import { CART_ID_KEY, USER_KEY } from '@shared/constants';

export type UserData = {
  email: string;
  owner_id: string;
  accessToken: string;
  refreshToken: string;
};

export const localStorageSetItem = (key: string, value: string) => {
  const { projectName } = config;
  try {
    localStorage.setItem(`${projectName}${key}`, value);
  } catch (error) {
    console.error('localStorageSetItem', error);
  }
};

export const localStorageGetItem = (key: string) => {
  const { projectName } = config;
  try {
    return localStorage.getItem(`${projectName}${key}`);
  } catch (error) {
    console.error('localStorageGetItem', error);
  }
};

export const localStorageRemoveItem = (key: string) => {
  const { projectName } = config;
  try {
    localStorage.removeItem(`${projectName}${key}`);
  } catch (error) {
    console.error('localStorageRemoveItem', error);
  }
};

export const getUserSession = (): UserData => {
  try {
    const userJson = localStorageGetItem(USER_KEY);
    const user = userJson ? JSON.parse(userJson) : null;
    return user;
  } catch (error) {
    console.error('ERROR GETTING USER SESSION', error);
  }
};

export const setUserSession = (userData: UserData) => {
  try {
    localStorageSetItem(USER_KEY, JSON.stringify(userData));
  } catch (error) {
    console.error('ERROR SETTING USER SESSION', error);
  }
};

export const deleteUserSession = () => {
  try {
    localStorageRemoveItem(USER_KEY);
  } catch (error) {
    console.error('ERROR DELETING USER SESSION', error);
  }
};

export const getCartIdSession = (): string => {
  try {
    return localStorageGetItem(CART_ID_KEY);
  } catch (error) {
    console.error('ERROR GETTING CART ID SESSION', error);
  }
};

export const setCartIdSession = (id: string) => {
  try {
    localStorageSetItem(CART_ID_KEY, id);
  } catch (error) {
    console.error('ERROR SETTING CART ID SESSION', error);
  }
};

export const deleteCartIdSession = () => {
  try {
    localStorageRemoveItem(CART_ID_KEY);
  } catch (error) {
    console.error('ERROR DELETING CART ID SESSION', error);
  }
};
