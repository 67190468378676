'use client';

import { createContext, useContext } from 'react';
import useFirebaseAuth from './useFirebaseAuth';

const authUserContext = createContext({
  user: { email: '' },
  loading: true,
  login: async (data: any) => false,
  signUp: async (email: string, password: string) => null as any,
  logOut: async () => {},
});

export function AuthUserProvider({ children }: { children: React.ReactNode }) {
  const auth = useFirebaseAuth();
  return <authUserContext.Provider value={auth}>{children}</authUserContext.Provider>;
}
export const useAuth = () => useContext(authUserContext);
